<template>
  <lottie-animation
    ref="anim"
    :animationData="require('@/assets/anim/phone-dash.json')"
    :loop="true"
    :autoPlay="true"
    :speed="1"
    class="svg_wrapper"
    style="width: 122px; height: 122px; transform: translate3d(0px, 0px, 0px) scale(1.25);"
  />
</template>
<script>
import LottieAnimation from 'lottie-web-vue';

export default {
  components: {
    LottieAnimation,
  },
};
</script>
