<template>
  <div class="chart-wrapper">
    <div class="chart-opt">
      <span>{{$t('sms.infos.outgoing-component.title')}}</span>
      <span
        class="material-symbols-outlined icon_opt"
        :class="[chartTypeLine === true ? 'active' : '']"
        @click="toggleLineChart"
      >
        show_chart
      </span>
      <span
        class="material-symbols-outlined icon_opt"
        :class="[chartTypePie === true ? 'active' : '']"
        @click="togglePieCHart"
      >
        data_usage
      </span>
    </div>
    <div class="chart" :class="[chartTypeLine === true ? '' : 'd-none']">
      <apexchart
        ref="lineChart"
        height="500"
        :options="line"
        :series="series_line"
      ></apexchart>
    </div>
    <div class="chart" :class="[chartTypePie === true ? '' : 'd-none']">
      <apexchart
        ref="pieChart"
        height="500"
        :options="pie"
        :series="series_pie"
      ></apexchart>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StatusChart',
  data() {
    return {
      line: {
        chart: { type: 'line' },
        /* series: [
            { name: '2017', type: 'line', data: [10, 20] },
            { name: '2018', type: 'line', data: [15, 25] },
          ], */
        /* labels: ['from', 'to'],
          stroke: { width: 5 }, */
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm',
          },
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        markers: {
          size: 5,
        },
      },
      series_line: [
        {
          name: this.$tc('app.pending', 1), // Pendente
          data: [11, 32, 45, 32, 34, 52, 41],
        },
        {
          name: this.$t('app.queued'), // Em Fila
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        {
          name: this.$tc('generic-str.sent-o', 1), // Enviado
          data: [15, 30, 75, 100, 14, 22, 19],
        },
        {
          name: this.$t('generic-str.status.lbl-error'), // Erro
          data: [9, 82, 47, 55, 12, 10, 18],
        },
        {
          name: this.$t('generic-str.status.lbl-delivered'), // Entregue
          data: [12, 22, 35, 47, 61, 72, 81],
        },
      ],
      pie: {
        chart: { type: 'pie' },
        labels: [this.$t('app.pending', 1), this.$t('app.queued'), this.$tc('generic-str.sent-o', 1), this.$t('generic-str.status.lbl-error'), this.$t('generic-str.status.lbl-delivered')],
        stroke: { width: 0 },
        plotOptions: {
          pie: {
            donut: {
              size: '55%',
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        },
      },
      series_pie: [41, 100, 19, 18, 81],
      stroke: {
        curve: 'straight',
      },
      chartTypeLine: true,
      chartTypePie: false,
    };
  },
  watch() {
    this.$refs.pieChart.render();
    this.$refs.lineChart.render();
  },
  methods: {
    toggleLineChart() {
      this.chartTypePie = false;
      this.chartTypeLine = true;
    },
    togglePieCHart() {
      this.chartTypeLine = false;
      this.chartTypePie = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-opt::v-deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.chart-opt span:first-of-type {
  margin-right: auto;
  font-weight: bold;
}
.icon_opt {
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 5px;
  font-size: 32px;
  max-width: 42px;
  cursor: pointer;
  color: var(--gray-font-color);
  transition: 0.5s;
}
.icon_opt.active {
  color: var(--clr-yup-purple);
}
.icon_opt:hover {
  background-color: #e7e7e7;
}
.darkmode .icon_opt {
  background-color: var(--background-3);
}
</style>
